<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper">
      <br />
      <span>
        <strong class="header-margin">{{ modalApproverData }}</strong> said
      </span>
      <span
        class="float-right text-muted pointer-on-hover margin-right"
        @click="toggleModal()"
      >
        <h3>&times;</h3>
      </span>
      <div class="message">
        {{ modalGeneralNoteData }}
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    modalApproverData: String,
    modalGeneralNoteData: String,
  },
};
</script>
<style scoped>
.message {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  height: 170px;
  overflow-y: scroll;
  background: #eaeaea;
  padding: 4%;
  padding-top: 5%;
  text-justify: inter-word;
}
.close-button {
  margin-top: 1%;
  margin-bottom: -3%;
  margin-left: 1%;
}
.header-margin {
  margin-left: 5%;
}
.margin-right {
  margin-right: 3%;
}
</style>