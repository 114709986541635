<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :hasButtonTopup="true" />
      </CCol>
    </CRow>

    <CRow>
      <CCol col="12" md="12">
        <CCard>
          <CCardHeader class="mt-3">
            <CRow>
              <CCol md="3" col="3">
                <h3>{{ $t("DISBURSEMENTS.TABLE.TITLE") }}</h3>
              </CCol>
              <CCol md="9" col="9">
                <CButton
                  size="sm"
                  color="durianprimary"
                  @click="goTo('/disbursements/create')"
                  v-element-access="permissions.disbursementCreate"
                  class="px-2 float-right"
                >
                  <CIcon name="cil-library-add" />
                  &nbsp;{{
                  $t("DISBURSEMENTS.CREATE_NEW")
                  }}
                </CButton>
                <CInput
                  class="search-input px-2 float-right"
                  :placeholder="$t('DISBURSEMENTS.SEARCH')"
                  v-model="disbursementFilters.search"
                  @input="getDisbursementDetails"
                />
                <CButton
                  @click="toggleAdvanceFilterModal"
                  size="sm"
                  color="lightborder"
                  class="px-2 float-right"
                >
                  <CIcon name="cil-filter" />
                  &nbsp;{{ $t("COMMON.FILTERS") }}
                </CButton>
                <date-picker
                  class="date px-2 float-right"
                  v-model="dates"
                  type="date"
                  range
                  placeholder="Select date range"
                  @change="selectRange"
                ></date-picker>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody class="p-0">
            <CRow class="mt-2 ml-2">
              <CCol class="pills-wrapper">
                <b-nav pills>
                  <b-nav-item @click="selectStatus('All')" :active="status === 'All'">All</b-nav-item>
                  <b-nav-item
                    @click="selectStatus('need_approval')"
                    :active="status === 'need_approval'"
                  >Need Approval</b-nav-item>
                  <b-nav-item
                    @click="selectStatus('need_revision')"
                    :active="status === 'need_revision'"
                  >Need Revision</b-nav-item>
                  <b-nav-item
                    @click="selectStatus('approved')"
                    :active="status === 'approved'"
                  >Approved</b-nav-item>
                  <b-nav-item @click="selectStatus('draft')" :active="status === 'draft'">Draft</b-nav-item>
                </b-nav>
              </CCol>
            </CRow>
            <hr class="nav-tabs mt-2" />
            <CDataTable
              class="m-4"
              hover
              :items="disbursements"
              :fields="fields"
              @row-clicked="(item, index) => checkStatusAndGo(item)"
            >
              <template #type="data">
                <td>
                  <div>{{ data.item.type }}</div>
                </td>
              </template>
              <template #id="data">
                <td>
                  <div>{{ data.item.id }}</div>
                </td>
              </template>
              <template #amount="data">
                <td>
                  <div
                    v-show="data.item.status !== 'draft'"
                  >Rp. {{ formatAmount(data.item.total_amount) }}</div>
                  <div v-show="data.item.status === 'draft'">
                    <svg
                      width="101"
                      height="19"
                      viewBox="0 0 101 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="101" height="19" fill="#C4C4C4" />
                    </svg>
                  </div>
                </td>
              </template>
              <template #total_disbursements="data">
                <td>
                  <div v-show="data.item.status !== 'draft'">{{ data.item.total_disbursements }}</div>
                  <div v-show="data.item.status === 'draft'">
                    <svg
                      width="44"
                      height="19"
                      viewBox="0 0 44 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="19" fill="#C4C4C4" />
                    </svg>
                  </div>
                </td>
              </template>
              <template #sender_uploader="data">
                <td>
                  <div>{{ data.item.created_by }}</div>
                </td>
              </template>

              <template #created_at="data">
                <td>
                  <div>{{ formatDate(data.item.created_at) }}</div>
                </td>
              </template>
              <template #general_notes="data">
                <td>
                  <div class="clickable-link">
                    <a
                      @click.stop="toogleNotesModal(data)"
                      v-show="data.item.general_notes"
                    >Read Notes</a>
                  </div>
                  <div v-show="!data.item.general_notes">n/a</div>
                </td>
              </template>
              <template #batch_name="data">
                <td>
                  <div>{{ data.item.name }}</div>
                </td>
              </template>
              <template #approver="data">
                <td>
                  <div>{{ data.item.approved_by }}</div>
                </td>
              </template>
              <template #status="data">
                <td>
                  <CBadge
                    :color="getDisbursementBadge(data.item.status)"
                    class="status-badge disbursement-list-status"
                  >{{ getStatusCapitalized(data.item.status) }}</CBadge>
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="disbursementFilters.limit"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <advance-filter-modal
      :isModalOpen="isFilterModalOpen"
      :toggleModal="toggleAdvanceFilterModal"
      :applyAdvanceFilter="applyAdvanceFilter"
    />
    <notes-modal
      :isModalOpen="isNotesModalOpen"
      :toggleModal="toogleNotesModal"
      :modalApproverData="modalApproverData"
      :modalGeneralNoteData="modalGeneralNoteData"
    />
  </div>
</template>

<script>
import BalanceCard from "./components/BalanceCard.vue";
import { listDisbursements } from "@/api/disbursement.api.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AdvanceFilterModal from "./components/modals/AdvanceFilterModal.vue";
import NotesModal from "./components/modals/NotesModal.vue";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Disbursements";
const DRAFT = "draft";
export default {
  components: {
    BalanceCard,
    DatePicker,
    AdvanceFilterModal,
    NotesModal
  },
  name: COMPONENT_NAME,
  data() {
    return {
      isValidatedCollapsed: false,
      isInvalidatedCollapsed: false,
      isNotesModalOpen: false,
      isFilterModalOpen: false,
      exportModal: false,
      visible: false,
      selected: null,
      totalRows: 0,
      modalApproverData: "",
      modalGeneralNoteData: "",
      fields: [
        {
          key: "type",
          label: this.$t("DISBURSEMENTS.TABLE.1"),
          _classes: "font-weight-bold"
        },
        {
          key: "id",
          label: this.$t("DISBURSEMENTS.TABLE.2"),
          _classes: "font-family-apercu"
        },
        {
          key: "amount",
          label: this.$t("DISBURSEMENTS.TABLE.3"),
          _classes: "font-family-apercu"
        },
        {
          key: "total_disbursements",
          label: this.$t("DISBURSEMENTS.TABLE.4"),
          _classes: "font-family-apercu"
        },
        {
          key: "sender_uploader",
          label: this.$t("DISBURSEMENTS.TABLE.5"),
          _classes: "font-family-apercu"
        },
        {
          key: "created_at",
          label: this.$t("DISBURSEMENTS.TABLE.6"),
          _classes: "font-family-apercu"
        },
        {
          key: "name",
          label: this.$t("DISBURSEMENTS.TABLE.7"),
          _classes: "font-family-apercu"
        },
        {
          key: "general_notes",
          label: "General Notes",
          _classes: "font-family-apercu"
        },
        {
          key: "approver",
          label: this.$t("DISBURSEMENTS.TABLE.8"),
          _classes: "font-family-apercu"
        },
        { key: "status", label: "Status", _classes: "font-family-apercu" }
      ],
      disbursementFilters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: ""
      },
      status: "All",
      disbursements: [],
      pageNumber: 1,
      value: "",
      valueto: "",
      today: "",
      before: "",
      range: {
        start: new Date(2020, 0, 1),
        end: new Date()
      },
      dates: [],
      singleChecked: false,
      batchChecked: false
    };
  },
  methods: {
    toogleNotesModal(data) {
      this.isNotesModalOpen = !this.isNotesModalOpen;
      if (this.isNotesModalOpen) {
        this.modalApproverData = data.item.approved_by;
        this.modalGeneralNoteData = data.item.general_notes;
      }
    },
    toggleAdvanceFilterModal() {
      this.isFilterModalOpen = !this.isFilterModalOpen;
    },
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.disbursementFilters.from = this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.disbursementFilters.to = this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.disbursementFilters.from = this.before;
        this.disbursementFilters.to = this.today;
      }
      this.getDisbursementsByDate();
    },
    onContext(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.disbursementFilters.from = date.toString();
      } else {
        this.disbursementFilters.from = "";
      }
      if (this.filterModal) {
        this.getDisbursementsByDate();
      }
    },
    onContextTo(ctx) {
      if (ctx.selectedDate != null) {
        let date = this.moment(ctx.selectedDate).format("MM-DD-YYYY");
        this.disbursementFilters.to = date.toString();
      } else {
        this.disbursementFilters.to = "";
      }
      if (this.filterModal) {
        this.getDisbursementsByDate();
      }
    },
    getDisbursementsByDate() {
      if (
        (this.disbursementFilters.from != "" &&
          this.disbursementFilters.to != "") ||
        (this.disbursementFilters.from == "" &&
          this.disbursementFilters.to == "")
      ) {
        this.getDisbursementDetails();
      }
    },
    pageChange(val) {
      this.disbursementFilters.skip =
        (val - 1) * this.disbursementFilters.limit;
      this.getDisbursementDetails();
    },
    selectStatus(val) {
      this.status = val;
      let filter = {};
      filter.column = "status";
      filter.name = val;
      if (val != "All") {
        this.statusFilter(filter);
      } else {
        this.clearStatusFilter(filter);
      }
    },
    removeFromDisbursementFilter(val) {
      let removeIndex = -1;
      this.disbursementFilters.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.disbursementFilters.filters.splice(removeIndex, 1);
      }
    },
    statusFilter(val) {
      this.removeFromDisbursementFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.disbursementFilters.filters.push(f);
      this.disbursementFilters.skip = 0;
      this.getDisbursementDetails();
    },
    clearStatusFilter(val) {
      this.value1 = null;
      let removeElementAtIndex = -1;
      this.disbursementFilters.filters.forEach(function(v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.disbursementFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getDisbursementDetails();
    },
    checkStatusAndGo(item) {
      if (item.status === DRAFT) {
        this.goTo("/disbursements/info/", item.id);
        return;
      }
      this.goTo("/disbursements/detail/", item.id);
    },
    async applyAdvanceFilter(filters) {
      this.disbursementFilters.filters = filters;
      await this.getDisbursementDetails();
    },
    async getDisbursementDetails() {
      try {
        const { skip, limit, from, to, search } = this.disbursementFilters;
        const filters = JSON.stringify(this.disbursementFilters.filters);
        const listParams = {
          skip,
          limit,
          from,
          to,
          search,
          filters
        };
        const response = await listDisbursements(listParams);
        this.disbursements = response.data.data.disbursements;
        this.count = response.data.data.count;
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_DISBURSEMENTS") +
            error.error
        );
      }
    },
    async initializeData() {
      const dates = await this.initializeDates();
      this.today = dates.today;
      this.before = dates.before;
      this.disbursementFilters.to = this.today;
      this.disbursementFilters.from = this.before;
      await this.getDisbursementDetails();
    }
  },
  mounted() {
    this.initializeData();
  },
  created() {
    this.$eventHub.$on(
      sandboxEventEmitter.prefix + COMPONENT_NAME,
      this.initializeData
    );
  },
  beforeDestroy() {
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>

<style scoped>
.search-input {
  width: 25%;
}
.date {
  width: 25%;
}
.disbursement-list-status {
  font-size: 12px;
}
.clickable-link {
  font-size: 14px;
  color: #418af8;
  text-decoration-line: underline;
  line-height: 17px;
  font-weight: bold;
  cursor: pointer;
}
</style>