<template>
  <CModal color="light" :centered="true" :show="isModalOpen">
    <div slot="header" />
    <div slot="header-wrapper" />
    <span class="header">{{ $t("DISBURSEMENTS.SELECT_FILTER_TO_APPLY") }}</span>
    <span class="float-right text-muted pointer-on-hover" @click="toggleModal()"
      ><h3>&times;</h3></span
    >
    <hr />
    <CContainer>
      <CRow class="mb-3">
        <strong class="col-12 col-xl-12 mb-2">
          {{ $t("DISBURSEMENTS.FILTER.TYPE") }}
        </strong>
        <table class="ml-5 mb-3">
          <tr class="mb-2">
            <div>
              <td>
                <input
                  type="checkbox"
                  v-model="typeChecks.single"
                  :checked="typeChecks.single"
                />
                Single
              </td>
              <td>
                <input
                  type="checkbox"
                  class="ml-4"
                  v-model="typeChecks.batch"
                  :checked="typeChecks.batch"
                />
                Batch
              </td>
            </div>
          </tr>
        </table>
      </CRow>

      <CRow class="mb-3">
        <strong class="col-12 col-xl-12 mb-2">{{
          $t("DISBURSEMENTS.FILTER.SENDER_UPLOADER")
        }}</strong>
        <table class="ml-5 mb-3">
          <tr v-for="(key, value) in makers" :key="value" class="mb-2">
            <div>
              <td v-if="value % 2 === 0 && makers[value]">
                <input
                  :key="value"
                  type="checkbox"
                  :checked="makerChecks[makers[value].id]"
                  v-model="makerChecks[makers[value].id]"
                />
                {{ makers[value].name }}
              </td>
              <td v-if="value % 2 === 0 && makers[value + 1]">
                <input
                  :key="value + 1"
                  type="checkbox"
                  class="ml-4"
                  :checked="makerChecks[makers[value + 1].id]"
                  v-model="makerChecks[makers[value + 1].id]"
                />
                {{ makers[value + 1].name }}
              </td>
            </div>
          </tr>
        </table>
      </CRow>
      <CRow class="mb-3">
        <strong class="col-12 col-xl-12 mb-2">{{
          $t("DISBURSEMENTS.FILTER.APPROVER")
        }}</strong>
        <table class="ml-5 mb-3">
          <tr v-for="(key, value) in approvers" :key="value" class="mb-2">
            <div>
              <td v-if="value % 2 === 0 && approvers[value]">
                <input
                  type="checkbox"
                  :key="value"
                  v-model="approverChecks[approvers[value].id]"
                  :checked="approverChecks[approvers[value].id]"
                />
                {{ approvers[value].name }}
              </td>
              <td v-if="value % 2 === 0 && approvers[value + 1]">
                <input
                  :key="value + 1"
                  type="checkbox"
                  class="ml-4"
                  v-model="approverChecks[approvers[value + 1].id]"
                  :checked="approverChecks[approvers[value + 1].id]"
                />
                {{ approvers[value + 1].name }}
              </td>
            </div>
          </tr>
        </table>
      </CRow>
    </CContainer>
    <template #footer>
      <CButton @click="resetFilters()">
        <strong>{{ $t("DISBURSEMENTS.RESET_FILTER") }}</strong>
      </CButton>
      &nbsp;
      <CButton @click="applyFilters()" color="durianprimary">{{
        $t("DISBURSEMENTS.APPLY_FILTER")
      }}</CButton>
    </template>
  </CModal>
</template>

<script>
import {
  getDisbursementApprovers,
  getDisbursementMakers,
  getDisbursementAdmins,
} from "@/api/disbursement.api.js";

const CREATED_BY = "created_by";
const APPROVED_BY = "approved_by";
const TYPE = "type";
export default {
  name: "AdvanceFilterModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    applyAdvanceFilter: Function,
  },
  data() {
    return {
      approverChecks: {},
      makerChecks: {},
      typeChecks: {
        single: false,
        batch: false,
      },
      approvers: [],
      makers: [],
      admins: [],
      filters: [],
    };
  },
  methods: {
    async getAdminsList() {
      const adminResponse = await getDisbursementAdmins();
      if (!adminResponse.data.data) {
        return;
      }
      this.admins = adminResponse.data.data;
    },
    async getApproversList() {
      try {
        const response = await getDisbursementApprovers();
        if (!response.data.data) {
          return;
        }
        this.approvers = response.data.data;
        for (let i = 0; i < this.approvers.length; i++) {
          this.approverChecks[this.approvers[i].id] = false;
        }
        for (let i = 0; i < this.admins.length; i++) {
          this.approvers.push({
            id: this.admins[i].id,
            name: this.admins[i].name,
            role: this.admins[i].role,
          });
          this.approverChecks[this.admins[i].id] = false;
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_MAKER_LIST") +
            error.error
        );
      }
    },
    async getMakersList() {
      try {
        const response = await getDisbursementMakers();
        if (!response.data.data) {
          return;
        }
        this.makers = response.data.data;
        for (let i = 0; i < this.makers.length; i++) {
          this.makerChecks[this.makers[i].id] = false;
        }
        for (let i = 0; i < this.admins.length; i++) {
          this.makers.push({
            id: this.admins[i].id,
            name: this.admins[i].name,
            role: this.admins[i].role,
          });
          this.makerChecks[this.admins[i].id] = false;
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_APPROVER_LIST")
        );
      }
    },
    removeFromDisbursementFilter(val) {
      let removeIndex = -1;
      this.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.filters.splice(removeIndex, 1);
      }
    },
    removeFilters() {
      this.removeFromDisbursementFilter(CREATED_BY);
      this.removeFromDisbursementFilter(APPROVED_BY);
      this.removeFromDisbursementFilter(TYPE);
    },
    resetFilters() {
      this.typeChecks.single = false;
      this.typeChecks.batch = false;
      this.removeFilters();
      this.applyAdvanceFilter(this.filters);
      for (let i = 0; i < this.approvers.length; i++) {
        this.approverChecks[this.approvers[i].id] = false;
      }
      for (let i = 0; i < this.makers.length; i++) {
        this.makerChecks[this.makers[i].id] = false;
      }
      this.toggleModal();
    },
    applyFilters() {
      this.removeFilters();
      this.addFilters(this.makerChecks, CREATED_BY);
      this.addFilters(this.approverChecks, APPROVED_BY);
      this.addFilters(this.typeChecks, TYPE);
      this.applyAdvanceFilter(this.filters);
      this.toggleModal();
    },
    addFilters(filter, column) {
      const filterLength = Object.keys(filter).length;
      let f = {};
      let filterValue = "(";
      f.key = column;

      for (let key in filter) {
        if (filter[key] === true) {
          if (column == "type") {
            filterValue = filterValue + "'" + key.toString() + "'";
          } else {
            filterValue = filterValue + key.toString();
          }
          filterValue = filterValue + ",";
        }
      }
      if (filterValue.length > 1)
        filterValue = filterValue.slice(0, filterValue.length - 1);
      f.value = filterValue + ")";
      if (f.value !== "()") {
        this.filters.push(f);
      }
    },
    async getLists() {
      await this.getAdminsList();
      await this.getMakersList();
      await this.getApproversList();
    },
  },
  mounted() {
    this.getLists();
  },
};
</script>